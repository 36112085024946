import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

import MaxWidth from 'components/functional/maxWidth'
import Layout from 'components/global/layout'
import PageHeader from 'components/shared/pageHeader'
import { media } from 'utils/Media'

const ThankYouStyles = styled.section`
  width: 100%;
  padding: 3rem 0;
  h1 {
    text-align: center;
    @media ${media.md} {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
    @media ${media.xl} {
      margin-bottom: 3rem;
    }
  }
  p {
    text-align: center;
    margin: 1.5rem auto;
    max-width: 400px;
  }
  div {
    text-align: center;
    button {
    }
  }
`

const ThankYouPage = ({ data, location }) => {
  const mob = data.mobHeaderImg
  const desk = data.deskHeaderImg
  return (
    <Layout>
      <PageHeader mobileHeader={mob} desktopHeader={desk} />
      <MaxWidth $black>
        <ThankYouStyles>
          {location?.state ? (
            <h1>{`Thank you ${location.state.name}`}</h1>
          ) : (
            <h1>Thank you</h1>
          )}
          <p>
            You've been added to the offical Mousetrap email list, keep an eye
            on your inbox
          </p>
          <div>
            <Link to="/">
              <button type="button" className="btn btn-main">
                <span>Home</span>
              </button>
            </Link>
          </div>
        </ThankYouStyles>
      </MaxWidth>
    </Layout>
  )
}

export default ThankYouPage

export const ThankYouQuery = graphql`
  query {
    faqs: allFaqsJson {
      nodes {
        question
        answer
      }
    }
    mobHeaderImg: file(name: { eq: "Detective_Trotter-mob" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    deskHeaderImg: file(name: { eq: "Detective_Trotter" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
